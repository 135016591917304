import React, { Fragment } from 'react';
import {
  arrayOf, func, number, shape, string,
} from 'prop-types';
import { onRollClick, waiting, currentBlock, onWonClick } from './BaseGame';
import BetSize from './BetSize';
import FlipButton from './FlipButton';
import CoinFlipRecap from './CoinFlipRecap';
import CoinFlipTransactions from './CoinFlipTransactions';
import WonButton from './WonButton';
import Transactions from './Transactions';
import WalletInfo from './WalletInfo';
import ProfitCalc from './ProfitCalc';
import {
  Networks,
} from '../utils/etheroll-contract';



function CoinFlip(props) {
  const {
    accountAddress, betSize, contract,
    filterTransactions, filteredTransactions, minBet, maxBet, network, jackpotBalance, ethblock, won, wonresult,
    accountBalance, contractAddress, contractBalance, tokenname, tokenDecimals,
    updateState,
  } = props;
  const rollUnder = 51;
  const bet = Math.round(Number(betSize / minBet));
  const onRollClickProps = {
    accountAddress, rollUnder, contract, bet, betSize,
  };
  var rollDisabled = accountAddress === null;
  const contractProps = {
      accountAddress, accountBalance, contractAddress, contractBalance, network, tokenname,
  };
  if (accountAddress === null || waiting) {
    rollDisabled = true;
  } else {
    rollDisabled = false;
  }
  var linkto = '';
  var iconto = '';
  if (contract != null) {
    var linkto = 'https://uniswap.exchange/swap?inputCurrency=ETH&outputCurrency='+contract.uffyi;
    var iconto = require('../assets/UNI-logo.png');
    
    if (network == Networks.bsc) { 
      linkto = 'https://exchange.pancakeswap.finance/#/swap?inputCurrency='+contract.uffyi;
      iconto = require('../assets/pancakeswap.png');
      if (contract.uffyi == '0xE3e8cC42DA487d1116D26687856e9FB684817c52') { 
        linkto = 'https://exchange.rasta.finance/';
        iconto = '/assets/RASTA-logo.png';
      }
    }
  }
  var jackpotBalancefixed = jackpotBalance.toFixed(2);
  if (jackpotBalance > 10000000) {
    jackpotBalancefixed = (jackpotBalance / 1000000).toFixed(0) + "MIL"
  }
  if (jackpotBalance > 10000000000) {
    jackpotBalancefixed = (jackpotBalance / 1000000000).toFixed(0) + "BIL"
  }
  return (
    <div className="row" style={{flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center'}}>
      <div className="col-lg-3"     style={{margin: '10px'}}>
        <h2 style={{color: '#CEDBDC'}}>Winning chance</h2>
        <div className="div midblock">
          <h1 style={{color: '#CEDBDC'}}>{ 50 }%</h1>
        </div>
        <ProfitCalc value={rollUnder} betSize={betSize}  tokenname={tokenname} />
        <img src={require('../assets/jackpot.png')} alt='Jackpot' height='180px' style={{ padding: '30px'}}/>
        <div className="div midblock">
          <h2 style={{color: '#CEDBDC'}}>{ jackpotBalancefixed } {tokenname}</h2>
        </div>
      </div>    
      {(() => {
        if ( rollDisabled || (currentBlock > 0 && ethblock > 0 && ethblock-currentBlock < 2) ) {
          return (
            <form className="RollUnder bigblock col-lg-5">
              <div style={{ width: '100%'}}><img src={require('../assets/coins.png')} alt='coins' height='180px' width='180px' style={{ margin: '50px'}}/></div>
              <FlipButton isDisabled={true} onClick={() => onRollClick(onRollClickProps)} />
            </form>
          )
        } else if (won > 0) {
          return (
            <form className="RollUnder bigblock col-lg-5">
              <div style={{ width: '100%'}}><img src={require('../assets/won.png')} alt='won' height='220px' width='220px' style={{ margin: '40px'}}/></div>
              <div style={{ width: '100%'}}><h2>Heads</h2></div>
              <WonButton isDisabled={rollDisabled} onClick={() => onWonClick(onRollClickProps)} />
            </form>
          )
        } else if (won == 0 && ethblock-currentBlock < 3) {
          return (
            <form className="RollUnder bigblock col-lg-5">
              <div style={{ width: '100%'}}><img src={require('../assets/loss.png')} alt='won' height='180px' width='180px' style={{ margin: '50px'}}/></div>
              <div style={{ width: '100%'}}><h2>Tails</h2></div>
              <FlipButton isDisabled={true} onClick={() => onRollClick(onRollClickProps)} />
            </form>
          )
        } else {
          return (
            <form className="RollUnder bigblock col-lg-5">
              <div className="flexaround">
                <img src={require('../assets/coin1.png')} alt='lose' height='120px'  width='120px' className="roundshadow" />
                <img src={require('../assets/coin2.png')} alt='won' height='120px' width='120px' className="roundshadow" />
              </div>
              <BetSize betSize={betSize} min={minBet} max={maxBet} updateBetSize={updateState('betSize')} />
              
              <FlipButton isDisabled={rollDisabled} onClick={() => onRollClick(onRollClickProps)} />
              <div className='unipoolbut' >
                <a href={ linkto } target="_blank" rel="noopener noreferrer"  >
                  <h3 style={{ color: 'white'}}>Buy {tokenname} <img alt="" src={ iconto } height="36px" style={{ verticalAlign: 'bottom' }} /></h3>
                </a>
              </div>
            </form>
          )
        }
      })()}
      <div className="col-lg-3" style={{ padding: '0'}}>
        <div className="bigblock transactions" style={{ height: '100%'}}>
          <Transactions
            network={network}
            tokenDecimals={tokenDecimals}
            onClick={transactionsFilter => filterTransactions(transactionsFilter)}
            transactions={filteredTransactions}
          />
        </div>
        <div className=" transactions" style={{width: '100%', position: 'absolute'}}>
          <WalletInfo {...contractProps} />
        </div>
      </div>
    </div>
  );
}

CoinFlip.propTypes = {
  accountAddress: string,
  betSize: number.isRequired,
  contract: shape({
    // TODO: seems completely ignored
    // https://github.com/facebook/prop-types/issues/181
    todo: number,
  }),
  filterTransactions: func.isRequired,
  filteredTransactions: arrayOf(shape({
    // TODO: seems completely ignored
    // https://github.com/facebook/prop-types/issues/181
    todo: number,
  })).isRequired,
  minBet: number.isRequired,
  maxBet: number.isRequired,
  network: number.isRequired,
  updateState: func.isRequired,
};
CoinFlip.defaultProps = {
  accountAddress: null,
  contract: null,
};

export default CoinFlip;
