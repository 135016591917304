import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';


class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.id,
    };


  }



  render() {
    const {
      token,
    } = this.state;
    
    const w1600x740 = '<div id="root"><iframe style="border: 0; margin: 0; padding: 0;" src="https://dice.fiscus.fyi/embed/coin-flip?c='+token+'" width="1600" height="840" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe></div>';

    const w800x740 = '<div id="root"><iframe style="border: 0; margin: 0; padding: 0;" src="https://dice.fiscus.fyi/embed/coin-flip?c='+token+'" width="800" height="1640" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe></div>';
    
    return (
      <div className="container">
        <div className="row" style={{flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center'}}>
          <div className="bigblock col-md-6">
          <h2 style={{ color: 'white'}}>Dice Widget</h2>
          <p style={{ color: 'white'}}>To embed a widget, copy the code in the Widget Code box and paste it into your website.</p>
          
          <h4 style={{ color: 'white'}}>1600x840 Widget Code:</h4>
            <div className="downblock">
              {w1600x740}
            
            </div>
            <h4 style={{ color: 'white'}}>800x1640 Widget Code:</h4>
            <div className="downblock">
              {w800x740}
            
            </div>
          
          </div>
        </div>
      </div>
    );
  }
}

export default Widget;
