import { toWei } from 'web3-utils';
import {
  tokenToWei, tokenFromWei,
} from '../utils/etheroll-contract';


const rp = require('request-promise');
var waiting = false;
var currentBlock = 0;



const onRollClick = ({
  accountAddress, rollUnder, contract, bet, betSize, 
}) => {
  try {
    waiting = true;
    console.log(currentBlock);


    contract.uffyiContract.methods.allowance(accountAddress, contract.address).call({ from: accountAddress, })
    .then(allowance => {
      const ethAllowance = tokenFromWei(allowance, contract.decimals);
      if(parseFloat(ethAllowance) < parseFloat(betSize)) {
        contract.uffyiContract.methods.approve(contract.address, contract.web3.utils.toWei("999999999999999", "ether")).send({ from: accountAddress, })
          .then(result => contract.web3Contract.methods.playerRollDice(rollUnder,bet).send({from: accountAddress, }))
          .then(result => {
            waiting = false;
            console.log(JSON.stringify(result));

            contract.web3.eth.getBlockNumber().then(result => { currentBlock = result });

          })
          .catch(error => { waiting = false; });

      } else {
          contract.web3Contract.methods.playerRollDice(rollUnder,bet).send({
            from: accountAddress,
          }).then(result => {
            waiting = false;
            console.log(JSON.stringify(result));

            contract.web3.eth.getBlockNumber().then(result => { currentBlock = result });

          })
          .catch(error => { waiting = false; });
      }
      
    })

  
  } catch(e) {
    waiting = false;
    console.log(e);
    console.log('exxxx');
  }
};

const onWonClick = ({
  accountAddress, rollUnder, contract, bet, betSize,
}) => {

    contract.web3Contract.methods.getReward().send({from: accountAddress, })
          .then(result => {
            console.log(JSON.stringify(result));
          })

};



export {
  onRollClick, 
  waiting,
  currentBlock,
  onWonClick,
};
