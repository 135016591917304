import React from 'react';
import './css/Footers.css';

function Footers() {
  return (
    <footer className="Footers d-none d-md-block">
      <div className="container">
        
        <ul style={{}}>
          <li style={{display: 'inline-block', padding: '14px'}}>
            <a href="http://kovan.fiscus.fyi/"  target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
              Kovan testnet
            </a>
          </li>
          <li style={{display: 'inline-block', padding: '14px'}}>
            <a href="https://github.com/Fiscus-fyi"  target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
              GitHub
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footers;
