import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { fromWei } from 'web3-utils';
import Alert from './Alert';
import CoinFlip from './CoinFlip';
import ContractInfo from './ContractInfo';
import RollUnder from './RollUnder';
import BetSize from './BetSize';
import ChanceOfWinning from './ChanceOfWinning';
import MetaMaskLink from './MetaMaskLink';
import getWeb3 from '../utils/get-web3';

import {
  EtherollContract, Networks, contractAddresses, tokenToWei, tokenFromWei,
} from '../utils/etheroll-contract';

const queryString = require('query-string');

const showMessage = (classType, message, updateAlertDict) => {
  const alertDict = { classType, message };
  updateAlertDict(alertDict);
};

const showFetchContractInfoWarning = (showWarningMessage, optionalMessage) => {
  const defaultMessage = "Can't fetch contract info.";
  const message = (typeof optionalMessage === 'undefined') ? defaultMessage : optionalMessage;
  showWarningMessage(message);
};

const minBetCallback = (showWarningMessage, updateValue, updateMax, updatebet, tokenDecimals) => (error, minBetWei) => {
  if (error) {
    showFetchContractInfoWarning(showWarningMessage)
  } else {
    updateValue(Number(tokenFromWei(minBetWei, tokenDecimals)));
    updateMax(Number(tokenFromWei(minBetWei, tokenDecimals))*10);
    updatebet(Number(tokenFromWei(minBetWei, tokenDecimals))*5);
  }
};

const nsymbolCallback = (showWarningMessage, updateValue) => (error, symbol) => {
  error ? showFetchContractInfoWarning(showWarningMessage) : (
    updateValue(symbol)
  );
};




const minNumberCallback = (showWarningMessage, updateValue) => (error, minNumber) => {
  error ? showFetchContractInfoWarning(showWarningMessage) : updateValue(minNumber - 1);
};

const maxNumberCallback = (showWarningMessage, updateValue) => (error, maxNumber) => {
  error ? showFetchContractInfoWarning(showWarningMessage) : updateValue(maxNumber - 1);
};

const getBalanceCallback = (showWarningMessage, updateValue, tokenDecimals) => (error, balance) => {
  // error can be null with the balance also null in rare cases
  (error || balance === null) ? showFetchContractInfoWarning("Can't fetch contract balance.") : (
    updateValue(Number(tokenFromWei(balance, tokenDecimals)))
  );
  console.log(balance);
};

const getBlockCallback = (showWarningMessage, updateValue) => (error, block) => {
  // error can be null with the balance also null in rare cases
  (error || block === null) ? showFetchContractInfoWarning("Can't fetch block.") : (
    updateValue(block)
    
  );
  console.log(block);
};

const getJackpotCallback = (showWarningMessage, updateValue, tokenDecimals) => (error, balance) => {
  // error can be null with the balance also null in rare cases
  (error || balance === null) ? showFetchContractInfoWarning("Can't fetch contract balance.") : (
    updateValue(Number(tokenFromWei(balance, tokenDecimals)))
  );
  console.log(balance);
};

const getWonCallback = (showWarningMessage, updateValue, tokenDecimals) => (error, balance) => {
  // error can be null with the balance also null in rare cases
  (error || balance === null) ? showFetchContractInfoWarning("Can't fetch contract balance.") : (
    updateValue(Number(tokenFromWei(balance, tokenDecimals)))
    
  );
  console.log(balance);
};

const getAccountBalanceCallback = (showWarningMessage, updateValue, tokenDecimals) => (error, balance) => {
  // error can be null with the balance also null in rare cases
  (error || balance === null) ? showWarningMessage("Can't fetch account balance.") : (
    updateValue(Number(tokenFromWei(balance, tokenDecimals)))
  );
};

const getWonResultCallback = (showWarningMessage, updateValue) => (error, resNumber) => {
  // error can be null with the balance also null in rare cases
  (error || resNumber === null) ? showFetchContractInfoWarning("Can't fetch result.") : (
      updateValue(Number(resNumber))
  );
  console.log(resNumber);
};


const getAccountsCallback = (
  web3, contract, showWarningMessage, updateAccountAddress, updateAccountBalance, updateWon, updateResult, tokenDecimals,
) => (error, accounts) => {
  if (error) {
    const message = "Can't retrieve accounts.";
    showWarningMessage(message);
  } else {
    const accountAddress = accounts.length === 0 ? null : accounts[0];
    if (accountAddress !== null) {
        contract.uffyiContract.methods.balanceOf(accountAddress).call(
          getAccountBalanceCallback(
            showWarningMessage,
            updateAccountBalance,
            tokenDecimals,
          ),
        );
        contract.web3Contract.methods.earned(accountAddress).call(
            getWonCallback(
              showWarningMessage, updateWon, tokenDecimals,
            ),
          );
        contract.web3Contract.methods.getresult(accountAddress).call(
            getWonResultCallback(
              showWarningMessage, updateResult, tokenDecimals,
            ),
          );
        
    }
    updateAccountAddress(accountAddress);
  }
};

const filterTransactions = (
  accountAddress, transactionsFilter, allTransactions,
  updateFilteredTransactions, updateTransactionsFilter,
) => {
  let filteredTransactions = allTransactions.slice();
  if (transactionsFilter === '#my-transactions') {
    filteredTransactions = allTransactions.filter(transaction => (
      transaction.logBetEvent.returnValues.PlayerAddress.toLowerCase()
      === accountAddress.toLowerCase()
    ));
  }
  updateFilteredTransactions(filteredTransactions);
  updateTransactionsFilter(transactionsFilter);
};

const getTransactions = (
  contract, accountAddress, transactionsFilter,
  updateAllTransactions, updateFilteredTransactions, updateTransactionsFilter,
) => {
  contract.getMergedTransactionLogs((error, result) => {
    if (error) {
      console.log(error);
    } else {
      const allTransactions = result;
      updateAllTransactions(allTransactions);
      filterTransactions(
        accountAddress, transactionsFilter, allTransactions,
        updateFilteredTransactions, updateTransactionsFilter,
      );
    }
  });
};

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.parsed = queryString.parse(props.location.search);
    this.state = {
      alertDict: {},
      betSize: 10,
      chances: 50,
      tokenaddress: null,
      tokenname: '',
      minBet: BetSize.defaultProps.min,
      maxBet: BetSize.defaultProps.max,
      minChances: ChanceOfWinning.defaultProps.min,
      maxChances: ChanceOfWinning.defaultProps.max,
      accountAddress: null,
      accountBalance: 0,
      network: Networks.kovan,
      contract: null,
      contractAddress: contractAddresses[Networks.kovan],
      contractBalance: 0,
      jackpotBalance: 0,
      ethblock:0,
      won:0,
      wonresult:0,

      // most recent transaction is last in the array
      allTransactions: [],
      filteredTransactions: [],
      transactionsFilter: '#all-transactions',
    };
    this.onWeb3 = this.onWeb3.bind(this);
    this.updateState = this.updateState.bind(this);
    this.initWeb3();
  }

  componentWillUnmount() {
    clearInterval(this.getTransactionsIntervalId);
  }

  /**
   * Retrieves web3 and contract info, then sets the following states:
   * - accountAddress
   * - accountBalance
   * - contract
   * - contractAddress
   * - contractBalance
   * - minBet
   * - maxBet (TODO)
   * - maxChances
   * - network
   */
  onWeb3(web3) {
    const getIdCallback = (network) => {
      console.log(this.parsed);
      const qaddress = this.parsed.c;
      console.log(qaddress);

      var contractAddress = contractAddresses[network];
      if (qaddress && web3.utils.isAddress(qaddress)) {
        contractAddress = qaddress;
      } 
      console.log(contractAddress);
      const contract = new EtherollContract(web3, contractAddress);
      const pullIntervalSeconds = 10 * 1000;
      const { showWarningMessage, updateState } = this;
      const { transactionsFilter, accountAddress, tokenaddress} = this.state;
      const getTransactionsAlias = () => getTransactions(
        contract, accountAddress, transactionsFilter,
        updateState('allTransactions'), updateState('filteredTransactions'), updateState('transactionsFilter'),
      );
      // clearInterval() is in the componentWillUnmount()
      this.getTransactionsIntervalId = setInterval(
        () => getTransactionsAlias(), pullIntervalSeconds,
      );
      getTransactionsAlias();
      this.setState({
        network,
        contract,
        contractAddress,
      });
      contract.web3Contract.methods.ntokenAddress().call().then(ntoken => {
      
          this.setState({ tokenaddress: ntoken });

          contract.uffyi = ntoken;
          contract.uffyiContract = new web3.eth.Contract(contract.erc20ABI, contract.uffyi);
          this.setState({ contract: contract });
          
          contract.uffyiContract.methods.symbol().call(
            nsymbolCallback(
              showWarningMessage, updateState('tokenname'),
            ),
          );
          contract.web3Contract.methods.minNumber().call(
            minNumberCallback(
              showWarningMessage, updateState('minChances'),
            ),
          );
          contract.web3Contract.methods.maxNumber().call(
            maxNumberCallback(
              showWarningMessage, updateState('maxChances'),
            ),
          );
          contract.uffyiContract.methods.decimals().call().then(tokenDecimals => {
              contract.decimals = tokenDecimals;
              this.setState({ contract: contract });
              this.setState({ tokenDecimals: tokenDecimals });
              contract.web3Contract.methods.minBet().call(
                minBetCallback(
                  showWarningMessage, updateState('minBet'), updateState('maxBet'), updateState('betSize'), tokenDecimals,
                ),
              );


              contract.uffyiContract.methods.balanceOf(contractAddress).call(
                getBalanceCallback(
                  showWarningMessage, updateState('contractBalance'), tokenDecimals,
                ),
              );

              contract.web3Contract.methods.jackpot().call(
                getJackpotCallback(
                  showWarningMessage, updateState('jackpotBalance'), tokenDecimals,
                ),
              );
              web3.eth.getAccounts(
                getAccountsCallback(
                  web3, contract, showWarningMessage, updateState('accountAddress'), updateState('accountBalance'), updateState('won'), updateState('wonresult'),  tokenDecimals,
                ),
              );
              

              
              if (accountAddress !== null) {
                contract.web3Contract.methods.earned(accountAddress).call(
                  getWonCallback(
                    showWarningMessage, updateState('won'),
                  ),
                );
              }
          });
      });
      web3.eth.getBlockNumber(
        getBlockCallback(
          showWarningMessage, updateState('ethblock'),
        ),
      );
      web3.eth.subscribe('newBlockHeaders', function(error, result){
        web3.eth.getBlockNumber(
          getBlockCallback(
            showWarningMessage, updateState('ethblock'),
          ),
        );
        
        contract.web3Contract.methods.jackpot().call(
          getJackpotCallback(
            showWarningMessage, updateState('jackpotBalance'), contract.decimals,
          ),
        );
        
        web3.eth.getAccounts(
          getAccountsCallback(
            web3, contract, showWarningMessage, updateState('accountAddress'), updateState('accountBalance'), updateState('won'), updateState('wonresult'), contract.decimals,
          ),
        );

        contract.uffyiContract.methods.balanceOf(contractAddress).call(
          getBalanceCallback(
            showWarningMessage, updateState('contractBalance'), contract.decimals,
          ),
        );



      });
      

    };
    web3.eth.net.getId().then(getIdCallback);
  }

  initWeb3() {
    const getWeb3CallbackOk = ({ web3 }) => {
      this.onWeb3(web3);
    };
    const getWeb3CallbackError = () => {
      const classType = 'danger';
      const message = (
        <Fragment>
          <FormattedMessage
            id="container.no-account-connected"
            defaultMessage={'No account connected, connect with a Web3-compatible wallet like {metamaskLink}'}
            values={{ metamaskLink: <MetaMaskLink /> }}
          />
        </Fragment>
      );
      showMessage(classType, message, this.updateState('alertDict'));
    };
    getWeb3.then(getWeb3CallbackOk, getWeb3CallbackError);
  }

  showWarningMessage(message) {
    const classType = 'warning';
    showMessage(classType, message, this.updateState('alertDict'));
  }

  updateState(key) {
    return (value) => {
      this.setState({ [key]: value });
    };
  }
  

  render() {
    const {
      alertDict, accountAddress, accountBalance, allTransactions, betSize, chances, contract,
      contractAddress, contractBalance, jackpotBalance, filteredTransactions, maxBet, minBet, maxChances,
      minChances, network, transactionsFilter, ethblock, won, wonresult, tokenname, tokenDecimals,
    } = this.state;

    const gameProps = {
      accountAddress,
      betSize,
      chances,
      contract,
      filteredTransactions,
      transactionsFilter,
      maxBet,
      minBet,
      maxChances,
      minChances,
      network,
      jackpotBalance,
      ethblock,
      won,
      wonresult,
      accountBalance, contractAddress, contractBalance, tokenname, tokenDecimals,
      updateState: this.updateState,
      filterTransactions: filter => filterTransactions(
        accountAddress, filter, allTransactions,
        this.updateState('filteredTransactions'), this.updateState('transactionsFilter'),
      ),
    };
    const contractProps = {
      accountAddress, accountBalance, contractAddress, contractBalance, network, tokenname,
    };
console.log(maxBet);

    return (
      <div className="container">
        <Alert classType={alertDict.classType} message={alertDict.message} />
        <Route path="/" exact render={() => <h2 className="sitename">Fiscus.fyi Etheroll</h2>} />
        <Route path="/coin-flip" exact render={() => <h2 className="sitename">Fiscus.fyi Coinflip</h2>} />
        <Route path="/embed" exact render={() => <h2 className="sitename">Etheroll</h2>} />
        <Route path="/embed/coin-flip" exact render={() => <h2 className="sitename">Coinflip</h2>} />
        <ContractInfo {...contractProps} />
        <Route path="/" exact render={() => <RollUnder {...gameProps} />} />
        <Route path="/coin-flip" render={() => <CoinFlip {...gameProps} />} />
        <Route path="/embed" exact render={() => <RollUnder {...gameProps} />} />
        <Route path="/embed/coin-flip" render={() => <CoinFlip {...gameProps} />} />
        <Route exact path='/'  render={() => <div className="addwidget">Add dice game to your site: <a href={ `/widget/${contractAddress}` }>Dice Widget</a></div>} />
        <Route exact path='/coin-flip'  render={() => <div className="addwidget">Add dice game to your site: <a href={ `/widget/${contractAddress}` }>Dice Widget</a>l</div>} />
      </div>
    );

    
  }
}

export default Container;
