import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import LanguageUpdate from './LanguageUpdate';

const Logo = () => (
  <a className="navbar-brand" href="https://fiscus.fyi">
    <img src={require('../assets/logo_night.png')} alt='Fiscus' height='48px'/>
  </a>
);

const HamburgerBtn = () => (
  <button
    className="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span className="navbar-toggler-icon" />
  </button>
);

const NavSections = () => (
  <div className="collapse navbar-collapse" id="navbarCollapse">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <a className="nav-link" href="/" exact >
          <FormattedMessage
            id="headers.navsections.navlink.home"
            defaultMessage="Etheroll"
          />
          <span className="sr-only">(current)</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/coin-flip" >
          <FormattedMessage
            id="headers.navsections.navlink.coin-flip"
            defaultMessage="Flip a coin"
          />
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://fiscus-fyi.gitbook.io/fiscus-fyi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="headers.navsections.navlink.about"
            defaultMessage="Documents"
          />
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="/all"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="headers.navsections.navlink.about"
            defaultMessage="Dice"
          />
        </a>
      </li>
       
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
       <a class="nav-link" href="https://twitter.com/fiscusfyi" target="_blank" rel="noopener noreferrer" ><img src={require('../assets/twitterlogo.png')} alt='Twitter' height='24px'/></a>
      </li>
      <li class="nav-item">
       <a class="nav-link" href="https://medium.com/@fiscus.fyi" target="_blank" rel="noopener noreferrer" ><img src={require('../assets/mediumlogo.png')} alt='Medium' height='24px'/></a>
      </li>
      <li class="nav-item">
       <a class="nav-link" href="https://t.me/fiscusfyi" target="_blank" rel="noopener noreferrer" ><img src={require('../assets/telegramlogo.png')} alt='Telegram' height='24px'/></a>
      </li>
      <li class="nav-item">
       <a class="nav-link" href="https://discord.gg/2a66cxX" target="_blank" rel="noopener noreferrer" ><img src={require('../assets/discordlogo.png')} alt='Discord' height='24px'/></a>
      </li>
    </ul>


  </div>
);

const Headers = () => (
  <header>
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <Logo />
      <HamburgerBtn />
      <NavSections />
    </nav>
  </header>
);

export default Headers;
