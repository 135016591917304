import React from 'react';
import {
  arrayOf, func, number, shape,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Address from './Address';
import Transaction from './Transaction';


function MergedLog({ network, mergedLog, tokenDecimals }) {
  const { logBetEvent, logResultEvent } = mergedLog;
  const playerNumber = Number(logBetEvent.returnValues.PlayerNumber);
  let valueEth = (logBetEvent.returnValues.RewardValue * (10 ** (-tokenDecimals))).toFixed(2);
  let diceResult = '?';
  let sign = '?';
  let znak = '';
  let earnResult = 0;
  let alertClass = 'secondary';
  let betValue = (logBetEvent.returnValues.BetValue * (10 ** (-tokenDecimals))).toFixed(2);
  let imacoin = <img src={require('../assets/c_resk.png')} alt='lose' height='18px' />
  // resolved bet case
  if (typeof logResultEvent !== 'undefined') {
    diceResult = Number(logResultEvent.returnValues.DiceResult);
    earnResult = Number(logResultEvent.returnValues.Value);
    const playerWon = earnResult < 1;
    valueEth = (logResultEvent.returnValues.Value * (10 ** (-tokenDecimals))).toFixed(2);
    sign = playerWon ? '<' : '>';
    znak = playerWon ? '-' : '+';
    alertClass = playerWon ? 'success' : 'danger';
    imacoin = playerWon ?  <img src={require('../assets/c_resk.png')} alt='lose' height='18px'/> : <img src={require('../assets/c_orel.png')} alt='won' height='18px' />
  }
  return (
    <div className={`row d-inline-flex downblock list-group-item list-group-item-${alertClass}`}>
      <div className="col-12">
        <div className="w-100" style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
          <div  className="col-5">
          <Address address={logBetEvent.returnValues.PlayerAddress.toString()} network={network} />
          </div>
          <div className="col-3">
          <Transaction hash={logBetEvent.transactionHash} network={network} value={betValue} />
          </div>
          <div className="col-1">
          {imacoin}
          </div>
          <div className="col-3">
          {znak}{valueEth}
          </div>
        </div>
      </div>
    </div>
  );
}
MergedLog.propTypes = {
  network: number.isRequired,
  mergedLog: shape({
    // TODO: seems completely ignored
    todo: number,
  }).isRequired,
};

function TransactionsFilterButtons({ onClick }) {
  return (
    <nav className="nav" style={{ justifyContent: 'center',}}>
      <button
        type="button"
        className="btn btn-link active"
        onClick={() => onClick('#all-transactions')}
      >
        <img src={require('../assets/alltx.png')} alt='All Transactions' height='32px' style={{ padding: '8px'}}/>
      </button>
      <button
        type="button"
        className="btn btn-link"
        onClick={() => onClick('#my-transactions')}
      >
        <img src={require('../assets/mytx.png')} alt='All Transactions' height='32px' style={{ padding: '8px'}}/>
      </button>
    </nav>
  );
}
TransactionsFilterButtons.propTypes = {
  onClick: func.isRequired,
};

function Transactions({ network, onClick, transactions, tokenDecimals }) {
  const reversedTransactions = transactions.slice().reverse();
  const transactionsElems = reversedTransactions.map(transaction => (
    <MergedLog
      key={transaction.logBetEvent.transactionHash}
      network={network}
      mergedLog={transaction}
      tokenDecimals={tokenDecimals}
    />
  ));
  return (
    <div className="card transactions ">
      <div className="card-header" style={{ padding: '0'}}>
        <TransactionsFilterButtons onClick={onClick} />
      </div>
      <div className="card-body">
        <div className="list-group">{transactionsElems}</div>
      </div>
    </div>
  );
}
Transactions.propTypes = {
  network: number.isRequired,
  onClick: func.isRequired,
  transactions: arrayOf(shape({
    // TODO: seems completely ignored
    // https://github.com/facebook/prop-types/issues/181
    todo: number,
  })).isRequired,
};


export default Transactions;
