import React from 'react';
import { string, number } from 'prop-types';
import { etherscanUrls } from '../utils/etheroll-contract';


function Address({ address, network }) {
  const url = `${etherscanUrls[network]}/address/${address}`;
  const smalladdr = address.substring(0, 6) + '...' + address.substring(40, 43);
  return <a href={url} className="text-monospace">{smalladdr}</a>;
}
Address.propTypes = {
  address: string.isRequired,
  network: number.isRequired,
};

export default Address;
