import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { IntlContext } from './contexts/IntlContext';
import Headers from './components/Headers';
import Footers from './components/Footers';
import Container from './components/Container';
import AddPool from './components/AddPool';
import PoolList from './components/PoolList';
import GameList from './components/GameList';
import Widget from './components/Widget';
import { messages } from './utils/locales';


const App = () => {
  const [locale] = React.useContext(IntlContext);
  return (
    <BrowserRouter>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div className="App">
          <Route exact path='/' component={Headers} />
          <Route exact path='/coin-flip' component={Headers} />
          <Route path='/addpool' component={Headers} />
          <Route path='/all' component={Headers} />
          <Route path='/game/:id' component={Headers} />
          <Route path='/widget/:id' component={Headers} />

          <Route exact path='/' component={Container} />
          <Route exact path='/coin-flip' component={Container} />
          <Route path='/embed' component={Container} />
          <Route path='/addpool' component={AddPool} />
          <Route path='/all' component={PoolList} />
          <Route path='/game/:id' component={GameList} />
          <Route path='/widget/:id' component={Widget} />

          <Route exact path='/' component={Footers} />
          <Route exact path='/coin-flip' component={Footers} />
          <Route path='/addpool' component={Footers} />
          <Route path='/all' component={Footers} />
          <Route path='/game/:id' component={Footers} />
          <Route path='/widget/:id' component={Footers} />
          

        </div>
      </IntlProvider>
    </BrowserRouter>
  );
};

export default App;
