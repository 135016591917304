import React from 'react';
import { FormattedMessage } from 'react-intl';
import { number } from 'prop-types';
import { getProfit } from '../utils/etheroll-contract';


function ProfitCalc({ betSize, value, tokenname, }) {
  const chances = value - 1;
  const profit = getProfit(betSize, chances);
  var profitfixed = profit.toFixed(2);
  if (profit > 10000000) {
    profitfixed = (profit / 1000000).toFixed(0) + "MIL"
  }
  if (profit > 7000000000) {
    profitfixed = (profit / 1000000000).toFixed(0) + "BIL"
  }
  return (
    <h6  style={{color: '#CEDBDC'}}>
        You will win 
          &nbsp;
          {profitfixed}
          &nbsp;
          {tokenname}
    </h6>
  );
}
ProfitCalc.propTypes = {
  betSize: number.isRequired,
  value: number.isRequired,
};

export default ProfitCalc;
