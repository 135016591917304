import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BN } from 'web3-utils';
import Alert from './Alert';
import MetaMaskLink from './MetaMaskLink';
import getWeb3 from '../utils/get-web3';

import {
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import {
  factoryABI, Networks, factoryAddresses, erc20ABI, dicepools, ourpools, EtherollContract, tokenToWei, tokenFromWei,
} from '../utils/etheroll-contract';

const showMessage = (classType, message, updateAlertDict) => {
  const alertDict = { classType, message };
  updateAlertDict(alertDict);
};

const showFetchContractInfoWarning = (showWarningMessage, optionalMessage) => {
  const defaultMessage = "Can't fetch contract info.";
  const message = (typeof optionalMessage === 'undefined') ? defaultMessage : optionalMessage;
  showWarningMessage(message);
};

const getBlockCallback = (showWarningMessage, updateValue) => (error, block) => {
  // error can be null with the balance also null in rare cases
  (error || block === null) ? showFetchContractInfoWarning("Can't fetch block.") : (
    updateValue(block)
    
  );
};

const getAccountsCallback = (
  showWarningMessage, updateAccountAddress, checkourpools, web3, factoryContract,
) => (error, accounts) => {
  if (error) {
    const message = "Can't retrieve accounts.";
    showWarningMessage(message);
  } else {
    const accountAddress = accounts.length === 0 ? null : accounts[0];
    updateAccountAddress(accountAddress);
    checkourpools(web3, factoryContract);
  }
};

class PoolList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertDict: {},
      tokenlist: [],
      network: Networks.kovan,
      factory: factoryAddresses[Networks.kovan],
      factoryContract: null,
      web3: null,
      ethblock:0,
      accountAddress: null,
      waiting:false,
      ourpoolsarr: [],
    };

    this.updateState = this.updateState.bind(this);
    this.onWeb3 = this.onWeb3.bind(this);
    this.checkourpools = this.checkourpools.bind(this);
    this.initWeb3();

  }

  componentWillUnmount() {
    clearInterval(this.getTransactionsIntervalId);
  }
  
  onWeb3(web3) {
    const getIdCallback = (network) => {

      var factory = factoryAddresses[network];
      if (network == Networks.mainnet) { 
        let ourpoolsarr = ourpools;
        this.setState({ ourpoolsarr });
      }
      
      console.log(factory);
      
      const factoryContract = new web3.eth.Contract(factoryABI, factory);
      const { showWarningMessage, updateState, } = this;
      
      this.setState({
        factory,
        factoryContract,
        web3,
        network,
      });
      const { checkourpools } = this;
      
      checkourpools(web3, factoryContract);
      
      web3.eth.getAccounts(
        getAccountsCallback(
          showWarningMessage, updateState('accountAddress'), checkourpools, web3, factoryContract,
        ),
      );
      
      web3.eth.subscribe('newBlockHeaders', function(error, result){
        web3.eth.getBlockNumber(
          getBlockCallback(
            showWarningMessage, updateState('ethblock'),
          ),
        );
        
      });
      
    };
    web3.eth.net.getId().then(getIdCallback);
  }

  initWeb3() {
    const getWeb3CallbackOk = ({ web3 }) => {
      this.onWeb3(web3);
    };
    const getWeb3CallbackError = () => {
      const classType = 'danger';
      const message = (
        <Fragment>
          <FormattedMessage
            id="container.no-account-connected"
            defaultMessage={'No account connected, connect with a Web3-compatible wallet like {metamaskLink}'}
            values={{ metamaskLink: <MetaMaskLink /> }}
          />
        </Fragment>
      );
      showMessage(classType, message, this.updateState('alertDict'));
    };
    getWeb3.then(getWeb3CallbackOk, getWeb3CallbackError);
  }

  showWarningMessage(message) {
    const classType = 'warning';
    showMessage(classType, message, this.updateState('alertDict'));
  };
  


  checkourpools(web3, factoryContract) {
    const {
      network,
      ourpoolsarr,
    } = this.state;
    console.log(network);
    ourpoolsarr.map((tokenpool) => {
        const contract = new EtherollContract(web3, tokenpool.pool);
        contract.web3Contract.methods.minBet().call()
            .then(result1 => {
              let val = [];
              val['minBet_'+tokenpool.pool] = Number(tokenFromWei(result1, tokenpool.decimals));
              this.setState(val);
            });
        contract.web3Contract.methods.jackpot().call()
            .then(result1 => {
              let val = [];
              val['jackpot_'+tokenpool.pool] = Number(tokenFromWei(result1, tokenpool.decimals));
              this.setState(val);
            });

          
        const tokenContract = new web3.eth.Contract(erc20ABI, tokenpool.token);
        tokenContract.methods.balanceOf(tokenpool.pool).call()
            .then(result1 => {
              let val = [];
              val['balance_'+tokenpool.pool] = Number(tokenFromWei(result1, tokenpool.decimals)).toFixed(2);
              this.setState(val);
            });

    });
    factoryContract.methods.allTokens().call()
        .then(result => { let tokenarray = [];
                          if (network == Networks.mainnet) { 
                            tokenarray.push({id:'FFYI',token:'0xca76BAa777d749De63Ca044853D22D56bC70bb47',});
                            tokenarray.push({id:'uFFYI',token:'0x021576770CB3729716CCfb687afdB4c6bF720CB6',});
                            tokenarray.push({id:'LINK',token:'0x514910771AF9Ca656af840dff83E8264EcF986CA',});
                            console.log('network mainnet');
                          }
                          result.map((tokenpool) => {
                            const tokenContract = new web3.eth.Contract(erc20ABI, tokenpool);
                            tokenContract.methods.symbol().call().then(nameresult => {
                              tokenarray.push({id:nameresult,token:tokenpool});
                            } );
                          })

              let tok = [];
              tok['tokenarray'] = tokenarray;
              this.setState(tok);
              console.log(JSON.stringify(tokenarray));
          
        })
    
  }
  
  
  updateState(key) {
    return (value) => {
      this.setState({ [key]: value });
    };
  }
  
  renderOurPools = () => {
    const {
      ourpoolsarr,
    } = this.state;
    return ourpoolsarr.map((ourpool) => {
      return this.renderOurPool(ourpool)
    })
  }

  renderOurPool = (ourpool) => {

    const minBet = this.state['minBet_'+ourpool.pool];
    const jackpot = this.state['jackpot_'+ourpool.pool];
    const balance = this.state['balance_'+ourpool.pool];
    const tName = ourpool.id;
    
    return (
      <div className='gameslist'>
        <div className='onegame'  >
          <div className='flexaround'  style={{ width: '100%'}} >
            <div className=''  >
              <h2>Etheroll</h2>
              <a href={ `/?c=${ourpool.pool}` }><img alt="" src={ '/assets/'+tName+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
            </div>
            <div className='downblock textend'  >
              <p>Contract balance: <span style={{ color: 'white'}}>{balance} {tName}</span></p>
              <p>Jackpot: <span style={{ color: 'white'}}>{jackpot} {tName}</span></p>
              <p>Minimum bet: <span style={{ color: 'white'}}>{minBet} {tName}</span></p>
              <p>Maximum bet: <span style={{ color: 'white'}}>{minBet*10} {tName}</span></p>

            </div>
          </div>
          <a href={ `/?c=${ourpool.pool}` } className='gamepoolbut' ><h5>{ourpool.id} Etheroll</h5></a>
        </div>
        <div className='onegame'  >
          <div className='flexaround'  style={{ width: '100%'}} >
            <div className=''  >
              <h2>Flip a coin</h2>
              <a href={ `/coin-flip?c=${ourpool}` }><img alt="" src={ '/assets/'+tName+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
            </div>
            <div className='downblock textend'  >
              <p>Contract balance: <span style={{ color: 'white'}}>{balance} {tName}</span></p>
              <p>Jackpot: <span style={{ color: 'white'}}>{jackpot} {tName}</span></p>
              <p>Minimum bet: <span style={{ color: 'white'}}>{minBet} {tName}</span></p>
              <p>Maximum bet: <span style={{ color: 'white'}}>{minBet*10} {tName}</span></p>

            </div>
          </div>
          <a href={ `/coin-flip?c=${ourpool.pool}` } className='gamepoolbut' ><h5>{ourpool.id} Flip a coin</h5></a>
        </div>
      </div>
    )
  }

  
  
  renderPools = (tokenarray) => {

    if (typeof tokenarray !== 'undefined') {
      return tokenarray.map((tokenpool) => {
        return this.renderPool(tokenpool)
      })
    } else {
     return (
       <div></div>
     ) 
    }
  }

  renderPool = (tokenpool) => {
    return (
      <div className='gamepool'  >
        <a href="" target="_blank"><img alt="" src={ '/assets/'+tokenpool.id+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
        <a className='gamepoolbut' href={ `/game/${tokenpool.token}` }><h5>{tokenpool.id}</h5></a>
      </div>
    )
  }


  render() {
    const {
      accountAddress,
      web3,
      factoryContract,
      alertDict,
      factory,
      tokenlist,
      waiting,
      tokenarray,
    } = this.state;
    


    
    return (
      <div className="container">
        <Alert classType={alertDict.classType} message={alertDict.message} />
        <div className="row" style={{flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center'}}>
          <div className="poollists">
            {
              this.renderOurPools()
            }
          </div>
          <div className="poollists">
            {
              this.renderPools(tokenarray)
            }
          </div>
          <div className="poolbtn">
            <a href="/addpool" className="btn btn-lg greenbutton" >
                  Add New Dice Pool
            </a>
          </div>
          
        </div>
      </div>
    );
  }
}

export default PoolList;
