import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { fromWei } from 'web3-utils';
import Alert from './Alert';
import MetaMaskLink from './MetaMaskLink';
import getWeb3 from '../utils/get-web3';

import {
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import {
  EtherollContract, factoryABI, Networks, factoryAddresses, erc20ABI, dicepools, ourpools, tokenToWei, tokenFromWei,
} from '../utils/etheroll-contract';

const showMessage = (classType, message, updateAlertDict) => {
  const alertDict = { classType, message };
  updateAlertDict(alertDict);
};

const showFetchContractInfoWarning = (showWarningMessage, optionalMessage) => {
  const defaultMessage = "Can't fetch contract info.";
  const message = (typeof optionalMessage === 'undefined') ? defaultMessage : optionalMessage;
  showWarningMessage(message);
};

const getBlockCallback = (showWarningMessage, updateValue) => (error, block) => {
  // error can be null with the balance also null in rare cases
  (error || block === null) ? showFetchContractInfoWarning("Can't fetch block.") : (
    updateValue(block)
    
  );
};

const getAccountsCallback = (
  showWarningMessage, updateAccountAddress, onCheckTokenClick
) => (error, accounts) => {
  if (error) {
    const message = "Can't retrieve accounts.";
    showWarningMessage(message);
  } else {
    const accountAddress = accounts.length === 0 ? null : accounts[0];
    updateAccountAddress(accountAddress);
    onCheckTokenClick();
  }
};

class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertDict: {},
      tokenlist: [],
      network: Networks.kovan,
      factory: factoryAddresses[Networks.kovan],
      factoryContract: null,
      web3: null,
      ethblock:0,
      accountAddress: null,
      waiting:false,
      token: props.match.params.id,
    };

    this.updateState = this.updateState.bind(this);
    this.onWeb3 = this.onWeb3.bind(this);
    this.initWeb3();

  }

  componentWillUnmount() {
    clearInterval(this.getTransactionsIntervalId);
  }
  
  onWeb3(web3) {
    const getIdCallback = (network) => {

      var factory = factoryAddresses[network];

      console.log(factory);
      
      const factoryContract = new web3.eth.Contract(factoryABI, factory);
      const { showWarningMessage, updateState, onCheckTokenClick } = this;
      const token = this.props.match.params.id;

      this.setState({
        factory,
        factoryContract,
        web3,
      });
      
      web3.eth.getAccounts(
        getAccountsCallback(
          showWarningMessage, updateState('accountAddress'), onCheckTokenClick,
        ),
      );
      
      web3.eth.subscribe('newBlockHeaders', function(error, result){
        web3.eth.getBlockNumber(
          getBlockCallback(
            showWarningMessage, updateState('ethblock'),
          ),
        );
        
      });
      
    };
    web3.eth.net.getId().then(getIdCallback);
  }

  initWeb3() {
    const getWeb3CallbackOk = ({ web3 }) => {
      this.onWeb3(web3);
    };
    const getWeb3CallbackError = () => {
      const classType = 'danger';
      const message = (
        <Fragment>
          <FormattedMessage
            id="container.no-account-connected"
            defaultMessage={'No account connected, connect with a Web3-compatible wallet like {metamaskLink}'}
            values={{ metamaskLink: <MetaMaskLink /> }}
          />
        </Fragment>
      );
      showMessage(classType, message, this.updateState('alertDict'));
    };
    getWeb3.then(getWeb3CallbackOk, getWeb3CallbackError);
  }

  showWarningMessage(message) {
    const classType = 'warning';
    showMessage(classType, message, this.updateState('alertDict'));
  };
  
  symbolCallback(showWarningMessage, token, factoryContract, accountAddress, web3, tokenDecimals,) {
    return (error, symbol) => {
      if (error) {
        showFetchContractInfoWarning(showWarningMessage) 
      } else {
        let nabe = [];
        nabe['tokenName'] = symbol;
        this.setState(nabe);

        let val = [];
        val['tokenAddress'] = token;
        this.setState(val);
        factoryContract.methods.allDicePools(token).call({ from: accountAddress, })
        .then(result => {
              let tok = [];
              tok['tokenlist'] = result;
              this.setState(tok);
              console.log(JSON.stringify(result));
              result.map((tokenpool) => {
                          const contract = new EtherollContract(web3, tokenpool);
                          contract.web3Contract.methods.minBet().call()
                              .then(result1 => {
                                let val = [];
                                val['minBet_'+tokenpool] = Number(tokenFromWei(result1, tokenDecimals));
                                this.setState(val);
                              });
                          contract.web3Contract.methods.jackpot().call()
                              .then(result1 => {
                                let val = [];
                                val['jackpot_'+tokenpool] = Number(tokenFromWei(result1, tokenDecimals));
                                this.setState(val);
                              });
                      
                          contract.web3Contract.methods.owner().call()
                              .then(result1 => {
                                let val = [];
                                val['owner_'+tokenpool] = result1;
                                this.setState(val);
                              });
                          contract.web3Contract.methods.balanceOf(accountAddress).call()
                              .then(result1 => {
                                let val = [];
                                let tokdec = (tokenDecimals < 6) ? tokenDecimals : 6;
                                val['ownliquidity_'+tokenpool] = Number(tokenFromWei(result1, tokenDecimals)).toFixed(tokdec);
                                this.setState(val);
                              });
                            
                          const tokenContract = new web3.eth.Contract(erc20ABI, token);
                          tokenContract.methods.balanceOf(tokenpool).call()
                              .then(result1 => {
                                let val = [];
                                val['balance_'+tokenpool] = Number(tokenFromWei(result1, tokenDecimals)).toFixed(2);
                                this.setState(val);
                                
                              });
              
                      })
              ourpools.filter((ourpool) => {
                              if(ourpool.token == token) {
                                return true
                              } else {
                                return false
                              }
                            }).map((ourpool) => {
                              
                                  const contract = new EtherollContract(web3, ourpool.pool);
                                  contract.web3Contract.methods.minBet().call()
                                      .then(result1 => {
                                        let val = [];
                                        val['minBet_'+ourpool.pool] = Number(tokenFromWei(result1, tokenDecimals));
                                        this.setState(val);
                                      });
                                  contract.web3Contract.methods.jackpot().call()
                                      .then(result1 => {
                                        let val = [];
                                        val['jackpot_'+ourpool.pool] = Number(tokenFromWei(result1, tokenDecimals));
                                        this.setState(val);
                                      });
                              
                                  contract.web3Contract.methods.owner().call()
                                      .then(result1 => {
                                        let val = [];
                                        val['owner_'+ourpool.pool] = result1;
                                        this.setState(val);
                                      });

                                    
                                  const tokenContract = new web3.eth.Contract(erc20ABI, token);
                                  tokenContract.methods.balanceOf(ourpool.pool).call()
                                      .then(result1 => {
                                        let val = [];
                                        val['balance_'+ourpool.pool] = Number(tokenFromWei(result1, tokenDecimals)).toFixed(2);
                                        this.setState(val);
                                        
                                      });
                              
                            })
            })
      }

    }
  }


  updateState(key) {
    return (value) => {
      this.setState({ [key]: value });
    };
  }
  


  onCheckTokenClick = () => {
    const {
      token,
      web3,
      factoryContract,
      accountAddress,

    } = this.state;
    const { showWarningMessage } = this;
    const tokenContract = new web3.eth.Contract(erc20ABI, token);
    tokenContract.methods.decimals().call().then(tokenDecimals => {
      tokenContract.methods.symbol().call(
        this.symbolCallback(
          showWarningMessage, token, factoryContract, accountAddress, web3, tokenDecimals,
        ),
      );
      let val = [];
      val['tokenDecimals'] = tokenDecimals;
      this.setState(val);
     
    });
  };
  
  onPoolEdit = (tokenpool) => {
    let val = [];
    val['editOpen_'+tokenpool] = true;
    this.setState(val);
  }
  onPoolEditClose = (tokenpool) => {
    let val = [];
    val['editOpen_'+tokenpool] = false;
    this.setState(val);
  }
  onChange = (event) => {
    let val = []
    val[event.target.id] = event.target.value
    this.setState(val)
  }

  onWithdrawClick = ({ accountAddress, tokenpool, web3, wamount, tokenDecimals, }) => {
    const contract = new EtherollContract(web3, tokenpool);
    contract.web3Contract.methods.withdraw(tokenToWei(wamount, tokenDecimals)).send({ from: accountAddress, })
                              .then(result1 => {
                                this.onPoolEditClose(tokenpool);
                              });
  };
  
  onAddliqClick = ({ accountAddress, tokenpool, web3, addamount, tokenDecimals, token, }) => {
    const contract = new EtherollContract(web3, tokenpool);
    const tokenContract = new web3.eth.Contract(erc20ABI, token);
    tokenContract.methods.allowance(accountAddress, tokenpool).call({ from: accountAddress, })
      .then(allowance => {
        const ethAllowance = tokenFromWei(allowance, tokenDecimals)
        if(parseFloat(ethAllowance) < (parseFloat(addamount))) {
          tokenContract.methods.approve(tokenpool, web3.utils.toWei("999999999999999", "ether")).send({ from: accountAddress, })
            .then(result => contract.web3Contract.methods.addliquidity(tokenToWei(addamount, tokenDecimals)).send({ from: accountAddress, }))
            .then(result => {
              this.onPoolEditClose(tokenpool);
              this.onCheckTokenClick();
            })
            .catch(error => { 
              this.onPoolEditClose(tokenpool);
              this.onCheckTokenClick();
            });

        } else {
            contract.web3Contract.methods.addliquidity(tokenToWei(addamount, tokenDecimals)).send({ from: accountAddress, })
                              .then(result1 => {
                                this.onPoolEditClose(tokenpool);
                                this.onCheckTokenClick();
                              });
        }
      })
  };
  
  renderOurPools = (token) => {
    return ourpools.filter((ourpool) => {
      if(ourpool.token == token) {
        return true
      } else {
        return false
      }
    }).map((ourpool) => {
      return this.renderOurPool(ourpool)
    })
  }

  renderOurPool = (ourpool) => {
    const {
          tokenName,
          web3,
          factoryContract,
          token,
          accountAddress,
          tokenDecimals,
          
        } = this.state;
    const minBet = this.state['minBet_'+ourpool.pool];
    const jackpot = this.state['jackpot_'+ourpool.pool];
    const balance = this.state['balance_'+ourpool.pool];
    const owner = this.state['owner_'+ourpool.pool];
    const tName = ourpool.id;
    const editOpen = this.state['editOpen_'+ourpool.pool];
    var njectedit = "";
    
    return (
      <div className='gameslist'>
        <div className='onegame'  >
          <div className='flexaround'  style={{ width: '100%'}} >
            <div className=''  >
              <h2>Etheroll</h2>
              <a href={ `/?c=${ourpool.pool}` }><img alt="" src={ '/assets/'+tName+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
            </div>
            <div className='downblock textend'  >
              <p>Contract balance: <span style={{ color: 'white'}}>{balance} {tName}</span></p>
              <p>Jackpot: <span style={{ color: 'white'}}>{jackpot} {tName}</span></p>
              <p>Minimum bet: <span style={{ color: 'white'}}>{minBet} {tName}</span></p>
              <p>Maximum bet: <span style={{ color: 'white'}}>{minBet*10} {tName}</span></p>

            </div>
          </div>
          <a href={ `/?c=${ourpool.pool}` } className='gamepoolbut' ><h5>{ourpool.id} Etheroll</h5></a>
        </div>
        <div className='onegame'  >
          <div className='flexaround'  style={{ width: '100%'}} >
            <div className=''  >
              <h2>Flip a coin</h2>
              <a href={ `/coin-flip?c=${ourpool}` }><img alt="" src={ '/assets/'+tName+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
            </div>
            <div className='downblock textend'  >
              <p>Contract balance: <span style={{ color: 'white'}}>{balance} {tName}</span></p>
              <p>Jackpot: <span style={{ color: 'white'}}>{jackpot} {tName}</span></p>
              <p>Minimum bet: <span style={{ color: 'white'}}>{minBet} {tName}</span></p>
              <p>Maximum bet: <span style={{ color: 'white'}}>{minBet*10} {tName}</span></p>

            </div>
          </div>
          <a href={ `/coin-flip?c=${ourpool.pool}` } className='gamepoolbut' ><h5>{ourpool.id} Flip a coin</h5></a>
        </div>
      </div>
    )
  }


  renderPools = (tokenlist) => {


    return tokenlist.map((tokenpool) => {
      return this.renderPool(tokenpool)
    })
  }

  renderPool = (tokenpool) => {
    const {
      tokenName,
      web3,
      factoryContract,
      token,
      accountAddress,
      tokenDecimals,
      
    } = this.state;
    const minBet = this.state['minBet_'+tokenpool];
    const jackpot = this.state['jackpot_'+tokenpool];
    const balance = this.state['balance_'+tokenpool];
    const owner = this.state['owner_'+tokenpool];
    const ownliquidity = this.state['ownliquidity_'+tokenpool];
    const editOpen = this.state['editOpen_'+tokenpool];
    var njectedit = "";
    const wamount = this.state['withdrawamount_'+tokenpool];
    const wamountError = this.state['withdrawamounterr_'+tokenpool];
    const addamount = this.state['addamount_'+tokenpool];
    const addamountError = this.state['addamounterr_'+tokenpool];
    var maxallowliquidity = '0';
    if (balance && minBet && balance>0 && minBet>0) {
      maxallowliquidity = (balance - minBet*21).toFixed(2);
    }
    const onWithdrawClickProps = {
      accountAddress, tokenpool, web3, wamount, tokenDecimals,
    };

    const onAddliqClickProps = {
      accountAddress, tokenpool, web3, addamount, tokenDecimals, token,
    };
    
    njectedit = <a onClick={ () => { this.onPoolEdit(tokenpool) } } className='gamepoolbut' >Manage</a>
    
    if (editOpen) {

      if (ownliquidity > 0) {
        njectedit = <div style={{ width: '100%'}}><form className="" style={{ paddingRight: '34px'}}>
                <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'addamount_'+tokenpool }
                  value={ addamount }
                  error={ addamountError }
                  onChange={ this.onChange }
                  placeholder={'Add Liquidity (' + tokenName + ')'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <button type="button" className="btn btn-lg greenbutton" disabled={false} onClick={() => this.onAddliqClick(onAddliqClickProps)} >Add Liquidity</button>,
                    
                  }}
                />
                <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'withdrawamount_'+tokenpool }
                  value={ wamount }
                  error={ wamountError }
                  onChange={ this.onChange }
                  placeholder={'Withdraw Amount, maximum  (' + ownliquidity + ' fDPT-' + tokenName + ')'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <button type="button" className="btn btn-lg greenbutton" disabled={false} onClick={() => this.onWithdrawClick(onWithdrawClickProps)} >Withdraw</button>,
                    startAdornment: <button type="button" className="btn btn-lg" disabled={false} onClick={() => { 
                      let val = [];
                      val['withdrawamount_'+tokenpool] = ownliquidity;
                      this.setState(val);
                    }} >Max</button>,
                  }}
                />
            </form>
            <a onClick={ () => { this.onPoolEditClose(tokenpool) } } className='gamepoolbut'  style={{ width: '100%', display: 'block', margin: '0',}}  >Cancel</a>
            </div>
        
      } else {
        njectedit = <div style={{ width: '100%'}}><form className="" style={{ paddingRight: '34px'}}>
                <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'addamount_'+tokenpool }
                  value={ addamount }
                  error={ addamountError }
                  onChange={ this.onChange }
                  placeholder={'Add Liquidity (' + tokenName + ')'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <button type="button" className="btn btn-lg greenbutton" disabled={false} onClick={() => this.onAddliqClick(onAddliqClickProps)} >Add Liquidity</button>,
                    
                  }}
                />
            </form>
            <a onClick={ () => { this.onPoolEditClose(tokenpool) } } className='gamepoolbut'  style={{ width: '100%', display: 'block', margin: '0',}}  >Cancel</a>
            </div>
      }
    }
    return (
      <div className='gameslist'>
        <div className='onegame'  >
          <div className='flexaround'  style={{ width: '100%'}} >
            <div className=''  >
              <h2>Etheroll</h2>
              <a href={ `/?c=${tokenpool}` }><img alt="" src={ '/assets/'+tokenName+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
            </div>
            <div className='downblock textend'  >
              <p>Contract balance: <span style={{ color: 'white'}}>{balance} {tokenName}</span></p>
              <p>Jackpot: <span style={{ color: 'white'}}>{jackpot} {tokenName}</span></p>
              <p>Minimum bet: <span style={{ color: 'white'}}>{minBet} {tokenName}</span></p>
              <p>Maximum bet: <span style={{ color: 'white'}}>{minBet*10} {tokenName}</span></p>

            </div>
          </div>
          <a href={ `/?c=${tokenpool}` } className='gamepoolbut' >{tokenpool}</a>
          {njectedit}
        </div>
        <div className='onegame'  >
          <div className='flexaround'  style={{ width: '100%'}} >
            <div className=''  >
              <h2>Flip a coin</h2>
              <a href={ `/coin-flip?c=${tokenpool}` }><img alt="" src={ '/assets/'+tokenName+'-logo.png' } height="64px" className='gamepoolimg'  /></a>
            </div>
            <div className='downblock textend'  >
              <p>Contract balance: <span style={{ color: 'white'}}>{balance} {tokenName}</span></p>
              <p>Jackpot: <span style={{ color: 'white'}}>{jackpot} {tokenName}</span></p>
              <p>Minimum bet: <span style={{ color: 'white'}}>{minBet} {tokenName}</span></p>
              <p>Maximum bet: <span style={{ color: 'white'}}>{minBet*10} {tokenName}</span></p>

            </div>
          </div>
          <a href={ `/coin-flip?c=${tokenpool}` } className='gamepoolbut' >{tokenpool}</a>
          {njectedit}
        </div>
      </div>
    )
  }


  render() {
    const {
      accountAddress,
      web3,
      factoryContract,
      alertDict,
      factory,
      tokenlist,
      waiting,

    } = this.state;
    
    const token = this.props.match.params.id;


    
    return (
      <div className="container">
        <Alert classType={alertDict.classType} message={alertDict.message} />
        <div className="row" style={{flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center'}}>
          <div style={{ width: '100%'}} className=''>
            {
              this.renderOurPools(token)
            }
            {
              this.renderPools(tokenlist)
            }
          </div>
          <div className="poolbtn">
            <a href="/addpool" className="btn btn-lg greenbutton" >
                  Add New Dice Pool
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default GameList;
