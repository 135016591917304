import React from 'react';
import Slider from 'rc-slider/lib/Slider';
import { func, number, string } from 'prop-types';
import 'rc-slider/assets/index.css';

var plusdisabled = false;
var minusdisabled = false;

function MinusVal(value, step, updateValue) {
  updateValue(value - step);
  console.log(value);
}

function PlusVal(value, step, updateValue) {
  updateValue(value + step);
  console.log(value);
}

function SetVal(value, updateValue) {
  updateValue(value);
  console.log(value);
}


function InputSpinner({
  value, updateValue, step, min, max,
}) {


  plusdisabled = value >= max;
  minusdisabled = value <= min;

  var formattedValue = value.toFixed(0);
  var strbut = min;
  var strval = "";
  if (value > 10000000) {
    strval = "MIL";
    formattedValue = (value / 1000000).toFixed(0) + strval
    strbut = (min / 1000000).toFixed(0)
  }
  
  if (value > 7000000000) {
    strval = <span>BIL</span>;
    formattedValue = (value / 1000000000).toFixed(0) + "BIL"
    strbut = (min / 1000000000).toFixed(0)
  }
  
  if (value < 1) {
    strval = "";
    formattedValue = value.toFixed(2)
    strbut = value.toFixed(2)
  }
  
  
  return (
    <div>
    <div class="inputspinner margin20">
      <div><button type="button" className="btn btn-lg squarebtn" onClick={ () => SetVal(min, updateValue) }>{strbut}{strval}</button></div>
      <div><button type="button" className="btn btn-lg squarebtn" onClick={ () => SetVal(min*3, updateValue) }>{strbut*3}{strval}</button></div>
      <div><button type="button" className="btn btn-lg squarebtn" onClick={ () => SetVal(min*5, updateValue) }>{strbut*5}{strval}</button></div>
      <div><button type="button" className="btn btn-lg squarebtn" onClick={ () => SetVal(min*7, updateValue) }>{strbut*7}{strval}</button></div>
      <div><button type="button" className="btn btn-lg squarebtn" onClick={ () => SetVal(min*10,   updateValue) }>{strbut*10}{strval}</button></div>
    </div>
    <div className="inputspinner">

      <div className="">
        <button
          type="button"
          className="btn btn-lg roundbtn"
          disabled={minusdisabled}
          onClick={ () => MinusVal(value, step, updateValue) }
        >
        -
        </button>
      </div>
      <div className="betsize">
        <h3 style={{ color: 'white'}}>{formattedValue}</h3>
      </div>
      <div className="">
        <button
          type="button"
          className="btn btn-lg roundbtn"
          disabled={plusdisabled}
          onClick={ () => PlusVal(value, step, updateValue) }
        >
        +
        </button>
      </div>
        
    </div>
    </div>
  );
}
InputSpinner.propTypes = {
  value: number.isRequired,
  updateValue: func.isRequired,
  step: number,
  min: number,
  max: number,
};
InputSpinner.defaultProps = {
  value: 10,
  step: 10,
  min: 10,
  max: 100,
};

export default InputSpinner;
