import React from 'react';
import { number, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ValueSlider from './ValueSlider';
import InputSpinner from './InputSpinner';

function BetSize({
  betSize, min, max, updateBetSize,
}) {
  
  return (
    <div className="form-group margin50">

      <InputSpinner
        value={betSize}
        updateValue={updateBetSize}
        step={min}
        min={min}
        max={max}
      />
      
    </div>
  );
}
BetSize.propTypes = {
  betSize: number.isRequired,
  min: number,
  max: number,
  updateBetSize: func.isRequired,
};
BetSize.defaultProps = {
  min: 10,
  max: 100,
  step: 10,
};

export default BetSize;
