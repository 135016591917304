import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { fromWei } from 'web3-utils';
import Alert from './Alert';
import MetaMaskLink from './MetaMaskLink';
import getWeb3 from '../utils/get-web3';

import {
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import {
  factoryABI, Networks, factoryAddresses, erc20ABI, dicepools, tokenToWei, tokenFromWei,
} from '../utils/etheroll-contract';

const showMessage = (classType, message, updateAlertDict) => {
  const alertDict = { classType, message };
  updateAlertDict(alertDict);
};

const showFetchContractInfoWarning = (showWarningMessage, optionalMessage) => {
  const defaultMessage = "Can't fetch contract info.";
  const message = (typeof optionalMessage === 'undefined') ? defaultMessage : optionalMessage;
  showWarningMessage(message);
};

const getBlockCallback = (showWarningMessage, updateValue) => (error, block) => {
  // error can be null with the balance also null in rare cases
  (error || block === null) ? showFetchContractInfoWarning("Can't fetch block.") : (
    updateValue(block)
    
  );
};

const getAccountsCallback = (
  showWarningMessage, updateAccountAddress,
) => (error, accounts) => {
  if (error) {
    const message = "Can't retrieve accounts.";
    showWarningMessage(message);
  } else {
    const accountAddress = accounts.length === 0 ? null : accounts[0];
    updateAccountAddress(accountAddress);
  }
};

class AddPool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertDict: {},
      tokenlist: [],
      network: Networks.kovan,
      factory: factoryAddresses[Networks.kovan],
      factoryContract: null,
      web3: null,
      ethblock:0,
      accountAddress: null,
      waiting:false,
      jackpotpercent:100,
      jackpot:0,
    };

    this.updateState = this.updateState.bind(this);
    this.onWeb3 = this.onWeb3.bind(this);
    this.initWeb3();

  }

  componentWillUnmount() {
    clearInterval(this.getTransactionsIntervalId);
  }
  
  onWeb3(web3) {
    const getIdCallback = (network) => {

      var factory = factoryAddresses[network];
      console.log(network);
      console.log(factory);
      
      const factoryContract = new web3.eth.Contract(factoryABI, factory);
      const { showWarningMessage, updateState } = this;

      this.setState({
        factory,
        factoryContract,
        web3,
      });
      
      web3.eth.getAccounts(
        getAccountsCallback(
          showWarningMessage, updateState('accountAddress'),
        ),
      );
      
      web3.eth.subscribe('newBlockHeaders', function(error, result){
        web3.eth.getBlockNumber(
          getBlockCallback(
            showWarningMessage, updateState('ethblock'),
          ),
        );
        
      });
      
    };
    web3.eth.net.getId().then(getIdCallback);
  }

  initWeb3() {
    const getWeb3CallbackOk = ({ web3 }) => {
      this.onWeb3(web3);
    };
    const getWeb3CallbackError = () => {
      const classType = 'danger';
      const message = (
        <Fragment>
          <FormattedMessage
            id="container.no-account-connected"
            defaultMessage={'No account connected, connect with a Web3-compatible wallet like {metamaskLink}'}
            values={{ metamaskLink: <MetaMaskLink /> }}
          />
        </Fragment>
      );
      showMessage(classType, message, this.updateState('alertDict'));
    };
    getWeb3.then(getWeb3CallbackOk, getWeb3CallbackError);
  }

  showWarningMessage(message) {
    const classType = 'warning';
    showMessage(classType, message, this.updateState('alertDict'));
  };
  
  symbolCallback(showWarningMessage, token, factoryContract, accountAddress) {
    return (error, symbol) => {
      if (error) {
        showFetchContractInfoWarning(showWarningMessage) 
      } else {
        let nabe = [];
        nabe['tokenName'] = symbol;
        this.setState(nabe);

        let val = [];
        val['tokenAddress'] = token;
        this.setState(val);
        factoryContract.methods.allDicePools(token).call({ from: accountAddress, })
        .then(result => {
              let tok = [];
              tok['tokenlist'] = result;
              this.setState(tok);
              console.log(JSON.stringify(result));
            })
      }

    }
  }
  
  decimalsCallback(showWarningMessage,) {
    return (error, decimals) => {
      if (error) {
        showFetchContractInfoWarning(showWarningMessage) 
      } else {
        let nabe = [];
        nabe['tokenDecimals'] = decimals;
        this.setState(nabe);
        console.log(decimals);

      }

    }
  }


  updateState(key) {
    return (value) => {
      this.setState({ [key]: value });
    };
  }
  
  onChange = (event) => {
    let val = []
    val[event.target.id] = event.target.value
    this.setState(val)
  }

  onBetChange = (event) => {
    let val = []
    val[event.target.id] = event.target.value
    this.setState(val)
    const minbet = event.target.value;
    const liquidity = this.state['liquidity'];
    if ( minbet*21 > liquidity || ( liquidity === undefined && minbet > 0) ) {
      let liq = []
      liq['liquidity'] = (minbet * 22).toFixed();
      this.setState(liq)
    }
  }

  onCheckTokenClick = ({
    accountAddress, token, web3, factoryContract,
  }) => {
    const { showWarningMessage } = this;
    const tokenContract = new web3.eth.Contract(erc20ABI, token);
    tokenContract.methods.symbol().call(
      this.symbolCallback(
        showWarningMessage, token, factoryContract, accountAddress,
      ),
    );
    tokenContract.methods.decimals().call(
      this.decimalsCallback(
        showWarningMessage,
      ),
    );
  };
  
  onAddPoolClick = ({
    accountAddress, token, web3, factoryContract, factory, liquidity, minbet, tokenDecimals, jackpot, jackpotpercent,
  }) => {
    const { showWarningMessage } = this;
    let val = [];
    try {
      
      const tokenContract = new web3.eth.Contract(erc20ABI, token);
      console.log(tokenToWei(minbet, tokenDecimals));
      console.log(tokenToWei(liquidity, tokenDecimals));

      tokenContract.methods.allowance(accountAddress, factory).call({ from: accountAddress, })
      .then(allowance => {
        const ethAllowance = tokenFromWei(allowance, tokenDecimals)
        if(parseFloat(ethAllowance) < (parseFloat(liquidity)+parseFloat(jackpot))) {
          tokenContract.methods.approve(factory, web3.utils.toWei("999999999999999", "ether")).send({ from: accountAddress, })
            .then(result => factoryContract.methods.newDicePool(token, tokenToWei(minbet, tokenDecimals), tokenToWei(liquidity, tokenDecimals), tokenToWei(jackpot, tokenDecimals), Number(jackpotpercent)).send({from: accountAddress, }))
            .then(result => {
              val['waiting'] = true
              this.setState(val)

              console.log(JSON.stringify(result));
            })
            .catch(error => { 
              val['waiting'] = true
              this.setState(val)
            });

        } else {
            factoryContract.methods.newDicePool(token, tokenToWei(minbet, tokenDecimals), tokenToWei(liquidity, tokenDecimals), tokenToWei(jackpot, tokenDecimals), Number(jackpotpercent)).send({
              from: accountAddress,
            }).then(result => {
              val['waiting'] = true
              this.setState(val)
              console.log(JSON.stringify(result));


            })
            .catch(error => { 
              val['waiting'] = true
              this.setState(val)
              
            });
        }
        
      })

    
    } catch(e) {
      val['waiting'] = true
      this.setState(val)
      console.log(e);
      console.log('exxxx');
    }
  };
  
  renderPools = (tokenlist) => {


    return tokenlist.map((tokenpool) => {
      return this.renderPool(tokenpool)
    })
  }

  renderPool = (tokenpool) => {
    return (
      <div className=''  >
        <a href={ `/?c=${tokenpool}` }>{tokenpool}</a>
      </div>
    )
  }


  render() {
    const {
      accountAddress,
      web3,
      factoryContract,
      alertDict,
      factory,
      tokenlist,
      waiting,
      tokenDecimals,
    } = this.state;
    

    var test = 0;

    const tokenAddress = this.state['tokenAddress'];
    const tokenName = this.state['tokenName'];
    const token = this.state['token'];
    const tokenError = this.state['token_error'];
    const minbet = this.state['minbet'];
    const minbetError = this.state['minbet_error'];
    const liquidity = this.state['liquidity'];
    const liquidityError = this.state['liquidity_error'];
    const jackpot = this.state['jackpot'];
    const jackpotError = this.state['jackpot_error'];
    const jackpotpercent = this.state['jackpotpercent'];
    const jackpotpercentError = this.state['jackpotpercent_error'];
    const onAddClickProps = {
      accountAddress, token, web3, factoryContract,
    };
    const onAddPoolProps = {
      accountAddress, token, web3, factoryContract, factory, liquidity, minbet, tokenDecimals, jackpot, jackpotpercent,
    };

    if (waiting  ) {
      setTimeout(function() {
        factoryContract.methods.allDicePools(token).call({ from: accountAddress, })
        .then(result => {
              let tok = [];
              tok['tokenlist'] = result;
              this.setState(tok);
              console.log(JSON.stringify(result));
             
            })      
        }.bind(this), 3000)
    }
    
    return (
      <div className="container">
        <Alert classType={alertDict.classType} message={alertDict.message} />
        <div className="row" style={{flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center'}}>
            <form className="RollUnder bigblock col-lg-5">
              <div style={{ width: '100%'}}></div>
              <div style={{ width: '100%'}}><h2>Add new pool</h2></div>
              
              
      {(() => {
        if ( tokenAddress == null ) {
          return (
            <form className="">
              <div style={{ width: '100%'}}></div>
                <div>
                <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'token' }
                  value={ token }
                  error={ tokenError }
                  onChange={ this.onChange }
                  placeholder="0x00..00 token address"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className=''><Typography variant='h3' className={ '' }>{ tokenName }</Typography></InputAdornment>,
                    
                  }}
                />
              </div>
              <button type="button" className="btn btn-lg greenbutton" disabled={false} onClick={() => this.onCheckTokenClick(onAddClickProps)} >
                Next
              </button>


            </form>
          )
        } else if (waiting) {
          return (
            <div>
              <div style={{ width: '100%'}} className='downblock'>
                <h4> { tokenName } pools </h4>
                {
                  this.renderPools(tokenlist)
                }
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div style={{ width: '100%'}} className='downblock'>
              <h4> { tokenName } pools </h4>
              {
                this.renderPools(tokenlist)
              }
              </div>
            <form className="">
              <div className="">
              Minimum Bet
              <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'minbet' }
                  value={ minbet }
                  error={ minbetError }
                  onChange={ this.onBetChange }
                  placeholder="Minimum Bet"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className=''><Typography variant='h3' className={ '' }>{ tokenName }</Typography></InputAdornment>,
                    
                  }}
                />
              Initial Jackpot
              <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'jackpot' }
                  value={ jackpot }
                  error={ jackpotError }
                  onChange={ this.onChange }
                  placeholder="Initial Jackpot"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className=''><Typography variant='h3' className={ '' }>{ tokenName }</Typography></InputAdornment>,
                    
                  }}
                />
              winning Jackpot percentage
              <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'jackpotpercent' }
                  value={ jackpotpercent }
                  error={ jackpotpercentError }
                  onChange={ this.onChange }
                  placeholder="winning Jackpot percentage"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className=''><Typography variant='h3' className={ '' }>%</Typography></InputAdornment>,
                    
                  }}
                />
              Pool Liquidity
              <TextField
                  fullWidth
                  disabled={ false }
                  className='diceinput'
                  id={ 'liquidity' }
                  value={ liquidity }
                  error={ liquidityError }
                  onChange={ this.onChange }
                  placeholder="Pool Liquidity"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className=''><Typography variant='h3' className={ '' }>{ tokenName }</Typography></InputAdornment>,
                    
                  }}
                />
              <button type="button" className="btn btn-lg greenbutton" disabled={false} onClick={() => this.onAddPoolClick(onAddPoolProps)} >
                Next
              </button>

              </div>
            </form>
            </div>
          )
        }
      })()}
      
      
              
            </form>
        </div>
      </div>
    );
  }
}

export default AddPool;
